<template>
  <div class="header">
    <div class="header-left">
      <!-- 显示传入的左侧内容，比如 "后台管理系统" 或者 "返回按钮" -->
      <slot name="left">
        <button v-if="showBackButton" @click="goBack" class="back-button">返回</button>
        <span>{{ organization  }} - 后台管理系统</span>
      </slot>
    </div>
    <div class="header-center">
      <slot name="title"></slot>
    </div>
    <div v-if="showUserInfo" class="header-right">
      <el-dropdown>
        <span class="el-dropdown-link">
          {{ realName }}
             <i class="iconfont icon-down-arrow"></i>
        </span>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item @click="goToProfile">个人信息</el-dropdown-item>
            <el-dropdown-item @click="handleLogout">退出</el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: {
    showBackButton: {
      type: Boolean,
      default: false, // 默认不显示返回按钮
    },
    showUserInfo: {
      type: Boolean,
      default: true, // 默认显示用户信息
    },
  },
  computed: {
    ...mapState({
      realName: state => state.userInfo.realName,
      organization: state => state.userInfo.organization
    }),
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    goToProfile() {
      this.$router.push('/profile');
    },
    handleLogout() {
      localStorage.clear()
      this.$router.push('/login');
    },
  },
};
</script>

<style scoped>
.header {
  height: 50px;
  line-height: 50px;
  background-color: rgb(36, 47, 66);
  display: flex;
  align-items: center;
  padding: 0 10px;
  color: white;
}
.el-dropdown-link {
  color: white; /* 添加此行以确保realName的颜色为白色 */
}
.header-left {
  width: 400px;
  font-weight: bold;
}
.header-center {
  flex: 1;
  text-align: center;
}
.header-right {
  width: 150px;
  display: flex;
  color: white;
  align-items: center;
  justify-content: center;
}
.back-button {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
}
</style>
