
import request from '@/utils/request';

export default {

      getHomeStats(oid) {
        return request({
            url: '/userHome/stats',
            method: 'get',
            params: { oid }

        });
    },

      getChartData(oid) {
        return request({
            url: '/userHome/chart',
            method: 'get',
            params: { oid }
        });
    }



};
