// utils/dateUtil.js

/**
 * 格式化完整的日期时间
 * @param {Date|string|number} dateTime 需要格式化的日期、日期时间字符串或时间戳
 * @returns {string} 返回格式化的日期时间字符串，如果输入无效则返回空字符串
 */
export function formatDateTime(dateTime) {
    if (!dateTime) return '';

    let date;
    if (dateTime instanceof Date) {
        date = dateTime;
    } else if (typeof dateTime === 'number') {
        date = new Date(dateTime);
    } else if (typeof dateTime === 'string') {
        // 尝试解析不同格式的日期字符串
        date = new Date(dateTime);
        if (isNaN(date.getTime())) {
            // 如果标准解析失败，尝试手动解析
            const parts = dateTime.split(/[- :T]/);
            date = new Date(parts[0], parts[1] - 1, parts[2], parts[3] || 0, parts[4] || 0, parts[5] || 0);
        }
    } else {
        console.error('Invalid date input:', dateTime);
        return '';
    }

    if (isNaN(date.getTime())) {
        console.error('Failed to parse date:', dateTime);
        return '';
    }

    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

/**
 * 仅格式化日期
 * @param {Date|string|number} dateTime 需要格式化的日期、日期时间字符串或时间戳
 * @returns {string} 返回格式化的日期字符串，如果输入无效则返回空字符串
 */
export function formatDate(dateTime) {
    if (!dateTime) return '';

    let date;
    if (dateTime instanceof Date) {
        date = dateTime;
    } else if (typeof dateTime === 'number') {
        date = new Date(dateTime);
    } else if (typeof dateTime === 'string') {
        // 尝试解析不同格式的日期字符串
        date = new Date(dateTime);
        if (isNaN(date.getTime())) {
            // 如果标准解析失败，尝试手动解析
            const parts = dateTime.split(/[- :T]/);
            date = new Date(parts[0], parts[1] - 1, parts[2]);
        }
    } else {
        console.error('Invalid date input:', dateTime);
        return '';
    }

    if (isNaN(date.getTime())) {
        console.error('Failed to parse date:', dateTime);
        return '';
    }

    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');

    return `${year}-${month}-${day}`;
}

/**
 * 格式化月份
 * @param {Date|string|number} dateTime 需要格式化的日期、日期时间字符串或时间戳
 * @returns {string} 返回格式化的年月字符串，如果输入无效则返回空字符串
 */
export function formatMonth(dateTime) {
    if (!dateTime) return '';

    let date;
    if (dateTime instanceof Date) {
        date = dateTime;
    } else if (typeof dateTime === 'number') {
        date = new Date(dateTime);
    } else if (typeof dateTime === 'string') {
        // 尝试解析不同格式的日期字符串
        date = new Date(dateTime);
        if (isNaN(date.getTime())) {
            // 如果标准解析失败，尝试手动解析
            const parts = dateTime.split(/[- :T]/);
            date = new Date(parts[0], parts[1] - 1, 1);
        }
    } else {
        console.error('Invalid date input:', dateTime);
        return '';
    }

    if (isNaN(date.getTime())) {
        console.error('Failed to parse date:', dateTime);
        return '';
    }

    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');

    return `${year}-${month}`;
}
