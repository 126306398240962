<!-- BaseDataView.vue -->
<template>
  <div class="base-data-view">
    <div class="button-group">
      <div>
        <el-button
            v-for="button in config.buttons"
            :key="button.text"
            :type="button.type"
            :disabled="button.disabled ? button.disabled(context) : false"
            @click="() => button.handler(context)"
        >
          {{ button.text }}
        </el-button>
      </div>
      <div>
        <el-input
            v-model="searchKeyword"
            style="width: 240px"
            :placeholder="config.searchPlaceholder || '请输入关键字'"
        />
        <el-button type="primary" style="margin-left: 10px" @click="handleSearch">查询</el-button>
      </div>
    </div>

    <div class="table-container" ref="tableContainerRef">
      <el-table
          :data="tableData"
          border
          :height="tableHeight"
          @selection-change="handleSelectionChange"
          :row-class-name="rowClassName"
          style="width: max-content;"
      >
        <el-table-column type="index" label="" width="60" align="center" fixed />
        <el-table-column type="selection" width="55" v-if="config.selectable" fixed />
        <el-table-column
            v-for="column in config.columns"
            :key="column.prop"
            :prop="column.prop"
            :label="column.label"
            :width="column.width || 150"
            :align="column.type === 'number' || column.type === 'date' ? 'right' : 'left'"
            header-align="center"
        >
          <template #default="scope">
            <template v-if="column.formatter">
              {{ column.formatter(scope.row, column) }}
            </template>
            <template v-else>
              {{ scope.row[column.prop] }}
            </template>
          </template>
        </el-table-column>

        <!-- 操作列 -->
        <el-table-column v-if="config.tableActions" :label="config.tableActions.columnName" :width="config.tableActions.width">
          <template #default="scope">
            <div>
              <template v-for="action in config.tableActions.buttons" :key="action.text">
                <!-- 如果是按钮类型 -->
                <el-button
                    v-if="action.componentType === 'button'"
                    :type="action.type"
                    :size="action.size"
                    @click="action.handler(scope.row, context)"
                >
                  {{ action.text }}
                </el-button>

                <!-- 如果是开关类型 -->
                <el-switch
                    v-if="action.componentType === 'switch'"
                    v-model="scope.row[action.prop]"
                    @change="action.handler(scope.row, context)"
                    :active-text="action.activeText"
                    :inactive-text="action.inactiveText"
                />
              </template>
            </div>
          </template>
        </el-table-column>

      </el-table>
    </div>

    <div class="table-footer">
      <div class="data-info">
        选择 {{ selectedRows ? selectedRows.length : 0 }} / {{ tableData ? tableData.length : 0 }} 条数据
      </div>
    </div>

    <el-dialog
        v-if="config.dialog"
        :title="formData.id ? '编辑' : '新建'"
        v-model="dialogVisible"
        :width="config.dialog.width || '50%'"
        :before-close="handleDialogClose"
        draggable
        :close-on-click-modal="false"
    >
      <el-form
          ref="formRef"
          :model="formData"
          :rules="config.rules"
          label-width="80px"
      >
        <el-row :gutter="20">
          <el-col v-for="field in config.dialog.fields" :key="field.prop" :span="field.span || 12">
            <el-form-item
                :label="field.label"
                :prop="field.prop"
                :rules="config.rules[field.prop]"
            >
              <!-- 自动完成输入框 -->
              <template v-if="field.type === 'autocomplete'">
                <el-autocomplete
                    v-model="formData[field.prop]"
                    :fetch-suggestions="(query, cb) => handleAutoComplete(query, cb, field)"
                    :placeholder="field.props?.placeholder"
                    :value-key="field.props?.valueKey"
                    :disabled="field.disabled"
                    :trigger-on-focus="false"
                    :debounce="500"
                    @select="(item) => handleAutoCompleteSelect(item, field)"
                    class="input-field"
                    :name="field.prop"
                >
                <template #default="{ item }">
                    <div class="suggestion-item">
                      <div>{{ item.emid }} - {{ item.name }} - {{ item.teamgroupName }}</div>
                      <div class="suggestion-address">{{ item.address}}</div>
                    </div>
                  </template>
                </el-autocomplete>
              </template>
              <!-- 数字输入框 -->
              <template v-else-if="field.type === 'number'">
                <el-input-number
                    v-model="formData[field.prop]"
                    :disabled="field.disabled"
                    v-bind="field.props || {}"
                    @change="(value) => handleFieldChange(value, field, formData)"
                    class="input-field"
                    style="width: 100%"
                />
              </template>
              <!-- 其他类型的输入框 -->
              <template v-else>
                <component
                    :is="getComponentType(field.type)"
                    v-model="formData[field.prop]"
                    :disabled="field.disabled"
                    v-bind="field.props || {}"
                    :rows="field.rows || 5"
                    :type="field.type === 'textarea' ? 'textarea' : 'text'"
                    class="input-field"
                    :name="field.prop"
                    @input="(value) => handleFieldChange(value, field, formData)"
                >
                  <template v-if="field.type === 'select'" #default>
                    <el-option
                        v-for="option in field.options"
                        :key="option.value"
                        :label="option.label"
                        :value="option.value"
                    />
                  </template>
                </component>
              </template>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogVisible = false">取消</el-button>
          <el-button type="primary" @click="validateAndSubmit">确定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import { ref, onMounted, watch, onUnmounted, reactive, nextTick } from 'vue';
import { useBaseDataOperations } from '@/composables/useBaseDataOperations';

const props = defineProps({
  config: Object,
  service: Object
});

// 1. 先声明 formRef
const formRef = ref(null);

// 2. 然后是其他的 refs 和方法
const {
  tableData,
  searchKeyword,
  selectedRows,
  formData,
  dialogVisible,
  searchEmployees,
  fetchData,
  handleSearch,
  handleSelectionChange,
  handleSizeChange,
  handleCurrentChange,
  handleDelete,
  handleImport,
  handleExport,
  handleDialogClose,
  handleDialogConfirm,
  rowClassName
} = useBaseDataOperations(props.config, props.service);

// 3. 最后创 context
const context = reactive({
  service: props.service,
  formRef,  // 现在可以安全地引用 formRef
  tableData,
  searchKeyword,
  selectedRows,
  formData,
  dialogVisible,
  searchEmployees,  // 需要这个方法
  fetchData,
  handleSearch,
  handleSelectionChange,
  handleSizeChange,
  handleCurrentChange,
  handleDelete,
  handleImport,
  handleExport,
  handleDialogClose,
  handleDialogConfirm,
  rowClassName
});

const tableContainerRef = ref(null);

const getComponentType = (type) => {
  switch (type) {
    case 'select':
      return 'el-select';
    case 'textarea':
      return 'el-input';
    case 'date':
      return 'el-date-picker';
    default:
      return 'el-input';
  }
};

// 处理字段值变化
const handleFieldChange = (value, field, formData) => {
  console.log('字段值变化:', field.prop, value, formData);
  if (field.props && field.props.onChange) {
    // 使用 nextTick 确保值更新后再触发回调
    nextTick(() => {
      field.props.onChange(value, formData);
    });
  }
};

// 处理自动完成搜索
const handleAutoComplete = async (query, cb, field) => {
  console.log('触发自动完成搜索:', query);  // 添加日志
  if (field.props?.onSearch) {
    try {
      const suggestions = await field.props.onSearch(query, context);
      console.log('获取到建议:', suggestions);  // 添加日志
      cb(suggestions);
    } catch (error) {
      console.error('搜索失败:', error);
      cb([]);
    }
  } else {
    cb([]);
  }
};

// 处理自动完成选择
const handleAutoCompleteSelect = (item, field) => {
  console.log('选中建议项:', item);  // 添加日志
  if (field.props?.onSelect) {
    field.props.onSelect(item, context);
  }
};

const tableHeight = ref(0);

const calculateTableHeight = () => {
  if (tableContainerRef.value) {
    tableHeight.value = tableContainerRef.value.clientHeight;
  }
};

onMounted(() => {
  fetchData();
  calculateTableHeight();
  window.addEventListener('resize', calculateTableHeight);
});

onUnmounted(() => {
  window.removeEventListener('resize', calculateTableHeight);
});

watch(searchKeyword, () => {
  if (searchKeyword.value === '') {
    fetchData();
  }
});

// 新增验证和提交方法
const validateAndSubmit = async () => {
  if (!formRef.value) return;

  try {
    await formRef.value.validate();
    // 验证通过，继续提交
    handleDialogConfirm();
  } catch (error) {
    console.error('表单验证失败:', error);
    return false;
  }
};
</script>

<style>
.base-data-view {
  display: flex;
  flex-direction: column;
  height: 100%;
  border: 1px solid #EBEEF5;
  border-radius: 4px;
}

.button-group {
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #EBEEF5;
}

.table-container {
  flex: 1;
  overflow-x: auto;
  overflow-y: hidden;
}

.table-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;
  border: 1px solid #EBEEF5;
}

.data-info {
  font-size: 14px;
  color: #606266;
}

.el-table {
  border: none;
}

/* 隐藏表格内部的滚动条 */
.el-table__body-wrapper::-webkit-scrollbar {
  display: none;
}

/* 自定义底部滚动条样式 */
.table-container::-webkit-scrollbar {
  height: 8px;
}

.table-container::-webkit-scrollbar-thumb {
  background-color: #909399;
  border-radius: 4px;
}

.table-container::-webkit-scrollbar-track {
  background-color: #f5f7fa;
}

/* 提高选中行样式的优先级 */
.el-table__body tr.selected-row td {
  background-color: rgb(166, 210, 255) !important;
}

/* 确保鼠标悬停时也保持选中的背景色 */
.el-table__body tr.selected-row:hover > td {
  background-color: rgb(166, 210, 255) !important;
}

/* 控制输入框的调整大小 */
.input-field {
  resize: none;
  width: 100%;
}

.input-field[type="textarea"] {
  resize: both;
  min-height: 100px;
}

/* 数字输入框样式 */
.el-input-number.input-field {
  width: 100%;
}

/* 移除数字输入框的上下箭头 */
.el-input-number.input-field .el-input-number__decrease,
.el-input-number.input-field .el-input-number__increase {
  display: none;
}

/* 调整数字输入框的内边距和对齐方式 */
.el-input-number.input-field .el-input__wrapper {
  padding-left: 11px;
  padding-right: 11px;
}

/* 数字输入框右对齐 */
.el-input-number.input-field .el-input__inner {
  text-align: right !important;
}

/* 移除浏览器默认的数字输入框箭头 */
.el-input-number.input-field input[type="number"]::-webkit-outer-spin-button,
.el-input-number.input-field input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.el-input-number.input-field input[type="number"] {
  -moz-appearance: textfield;
}

/* 自动完成下拉框样式 */
.suggestion-item {
  padding: 4px 0;
}

.suggestion-address {
  font-size: 12px;
  color: #999;
}

/* 添加到现有的 style 部分 */
.el-table .cell {
  text-align: inherit !important;
}

.el-table .is-right {
  text-align: right !important;
}

.el-table .is-left {
  text-align: left !important;
}

/* 导入错误对话框的主容器样式 */
body .el-message-box.import-error-dialog {
    /* 设置对话框的宽度 */
    width: 600px !important;     /* 修改为 600px */
    max-width: 95vw;            /* 防止在小屏幕上超出视窗 */
    min-width: 400px;           /* 调整最小宽度 */
    
    /* 固定定位在屏幕中央 */
    position: fixed !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;  /* 使用 transform 实现精确居中 */
    margin: 0 !important;
    
    /* 弹窗基本设置 */
    resize: none;             /* 禁用大小调整功能 */
    display: flex;            /* 使用 flex 布局 */
    flex-direction: column;   /* 垂直方向排列子元素 */
    height: 400px;           /* 修改为 400px */
    overflow: hidden;        /* 防止内容溢出 */
}

/* 对话框标题区域样式 */
.import-error-dialog .el-message-box__header {
    flex: none;              /* 防止标题区域被压缩或拉伸 */
    height: 40px;            /* 减小标题高度 */
    padding: 10px 15px;      /* 适当减小内边距 */
    box-sizing: border-box;  /* 将padding计入总高度 */
    border-bottom: 1px solid #ebeef5;  /* 底部边框线 */
    display: flex;           /* 使用 flex 布局 */
    align-items: center;     /* 垂直居中 */
    justify-content: space-between;  /* 标题和关闭按钮两端对齐 */
}

/* 对话框内容区域容器 */
.import-error-dialog .el-message-box__content {
    flex: 1;                /* 占据剩余所有空间 */
    min-height: 0;          /* 允许内容区域在flex布局中收缩 */
    position: relative;     /* 为绝对定位的子元素提供参考 */
    padding: 0;
    margin: 0;
}

/* 消息内容区域 */
.import-error-dialog .el-message-box__message {
    /* 绝对定位，填充整个内容区域 */
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: auto;       /* 只在垂直方向显示滚动条 */
    margin: 0;
    padding: 0;
}

/* 具体的内容样式 */
.import-error-content {
    padding: 15px 20px;     /* 略微减小内边距 */
    background-color: #fff;
    color: #303133;
    /* 设置字体 */
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    font-size: 14px;
    line-height: 1.8;
    width: 100%;
    box-sizing: border-box;
    white-space: pre-wrap;  /* 保留换行和空格 */
    word-break: break-word; /* 允许在单词内换行 */
}

/* 按钮区域样式 */
.import-error-dialog .el-message-box__btns {
    flex: none;             /* 防止按钮区域被压缩或拉伸 */
    height: 50px;           /* 减小按钮区域高度 */
    padding: 10px 15px;     /* 适当减小内边距 */
    border-top: 1px solid #ebeef5;  /* 顶部边框线 */
    margin: 0;
    display: flex;          /* 使用 flex 布局 */
    align-items: center;    /* 垂直居中 */
    justify-content: flex-end;  /* 按钮靠右对齐 */
    background-color: #fff;
}

/* 自定义滚动条样式 */
.import-error-dialog .el-message-box__message::-webkit-scrollbar {
    width: 6px;             /* 滚动条宽度 */
}

/* 滚动条滑块样式 */
.import-error-dialog .el-message-box__message::-webkit-scrollbar-thumb {
    background: #909399;     /* 滚动条颜色 */
    border-radius: 3px;      /* 圆角 */
}

/* 滚动条轨道样式 */
.import-error-dialog .el-message-box__message::-webkit-scrollbar-track {
    background: #f5f7fa;     /* 轨道背景色 */
}
</style>
