import request from '@/utils/request';

export function uploadFile(formData) {
    return request({
        url: '/minio/upload',
        method: 'post',
        data: formData,
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
}

export function getFileUrl(fileName) {
    console.log(fileName)
    return request({
        url: '/minio/file-url',
        method: 'get',
        params: { fileName }
    });
}
