import { createStore } from 'vuex';
import userApi from '../api/user';

export default createStore({
  state: {
    accessToken: sessionStorage.getItem('accessToken') || null,
    refreshToken: sessionStorage.getItem('refreshToken') || null,
    userInfo: JSON.parse(sessionStorage.getItem('userInfo') || '{}')
  },
  mutations: {
    setAccessToken(state, token) {
      state.accessToken = token;
      sessionStorage.setItem('accessToken', token);
    },
    setRefreshToken(state, token) {
      state.refreshToken = token;
      sessionStorage.setItem('refreshToken', token);
    },
    setUserInfo(state, userInfo) {
      state.userInfo = userInfo;
      sessionStorage.setItem('userInfo', JSON.stringify(userInfo));
    },
    clearUserData(state) {
      state.accessToken = null;
      state.refreshToken = null;
      state.userInfo = {};
      sessionStorage.clear();
    }
  },
  actions: {
    login({ commit }, { accessToken, refreshToken, ...userData }) {
      commit('setAccessToken', accessToken);
      commit('setRefreshToken', refreshToken);
      commit('setUserInfo', userData);
    },
    logout({ commit }) {
      commit('clearUserData');
    },
    async refreshToken({ commit }, refreshToken) {
      try {
        const response = await userApi.refreshTokenAPI(refreshToken);
        commit('setAccessToken', response.data.accessToken);
        return response.data.accessToken;
      } catch (error) {
        commit('clearUserData');
        throw error;
      }
    }
  },
  getters: {
    isAuthenticated(state) {
      return !!state.accessToken;
    },
    getAccessToken(state) {
      return state.accessToken;
    },
    getUserInfo(state) {
      return state.userInfo;
    },
    getOid: (state) => {
      return state.userInfo?.oid;
    }
  }
});
