<template>
  <div class="UserHome">
    <div class="stats">
      <div class="stat" v-for="(value, label) in stats" :key="label">
        <h3>{{ label }}</h3>
        <p>{{ value }}</p>
      </div>
    </div>
    <div class="content-wrapper">
      <div class="chart-wrapper">
        <BarChart :chartData="chartData" :options="chartOptions"></BarChart>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed } from 'vue';
import BarChart from '../../components/BarChart.vue';
import userHomeService from "@/api/userHomeService";
import { useStore } from "vuex";
import { ElMessage } from "element-plus";
import ChartDataLabels from 'chartjs-plugin-datalabels';

export default {
  name: 'UserHome',
  components: {
    BarChart,
  },
  setup() {
    const store = useStore();
    const oid = computed(() => store.getters.getOid);

    // 验证 OID
    if (!oid.value) {
      console.error('OID is not defined');
      ElMessage.error('OID 未定义');
      return;
    }

    const stats = ref({
      '工人总数': 0,
      '在职人数': 0,
      '预警人数': 0,
      '黑名单': 0,
    });

    const chartData = ref({
      labels: ['20岁以下', '20~30岁', '30~40岁', '40~50岁', '50~60岁', '60岁以上'],
      datasets: [
        {
          label: '年龄分布统计',
          backgroundColor: 'rgba(75,192,192,0.2)',
          borderColor: 'rgba(75,192,192,1)',
          borderWidth: 1,
          data: [],
        },
      ],
    });

    const chartOptions = ref({
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        x: {
          display: true,
          title: {
            display: true,
            text: '年龄段',
          },
        },
        y: {
          display: true,
          title: {
            display: true,
            text: '人数',
          },
          beginAtZero: true,
        },
      },
    });

    const fetchStats = async () => {
      try {
        const response = await userHomeService.getHomeStats(oid.value);
        const data = response.data;
        stats.value['工人总数'] = data['total'];
        stats.value['在职人数'] = data['onjobNumber'];
        stats.value['预警人数'] = data['warnNumber'];
        stats.value['黑名单'] = data['blacklistNumber'];
      } catch (error) {
        console.error('Error fetching user stats:', error);
      }
    };

    const fetchAgeDistribution = async () => {
      try {
        const response = await userHomeService.getChartData(oid.value);
        const data = response.data;
        
        // 计算每个年龄段的离职人数
        const inactiveCounts = data.totalCounts.map((total, index) => 
          total - data.activeCounts[index]
        );
        
        // 计算最大值用于设置Y轴刻度
        const maxValue = Math.max(...data.totalCounts);

        chartData.value = {
          labels: data.labels,
          datasets: [
            {
              label: '在职',
              backgroundColor: '#4285f4',
              data: data.activeCounts,
              borderWidth: 0,
              barPercentage: 0.8,    // 调整柱子宽度
              categoryPercentage: 0.7 // 调整类别间距
            },
            {
              label: '离职',
              backgroundColor: '#ff7043',
              data: inactiveCounts,
              borderWidth: 0,
              barPercentage: 0.8,    // 调整柱子宽度
              categoryPercentage: 0.7 // 调整类别间距
            }
          ]
        };

        chartOptions.value = {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: true,
              position: 'top',
              align: 'end',
              labels: {
                usePointStyle: true,
                boxWidth: 10,
                padding: 15,
                font: {
                  size: 13
                }
              }
            },
            title: {
              display: true,
              text: '员工年龄分布',
              align: 'center',
              font: {
                size: 18,
                weight: 'bold'
              },
              padding: {
                top: 10,
                bottom: 20
              }
            },
            tooltip: {
              enabled: true,
              mode: 'index',
              intersect: false,
              callbacks: {
                label: function(context) {
                  const label = context.dataset.label || '';
                  const value = context.parsed.y || 0;
                  return `${label}: ${value}人`;
                }
              }
            }
          },
          scales: {
            x: {
              stacked: true,     // 确保 X 轴堆叠
              grid: {
                display: false
              },
              ticks: {
                font: {
                  size: 12
                }
              }
            },
            y: {
              stacked: true,     // 确保 Y 轴堆叠
              beginAtZero: true,
              max: Math.ceil(maxValue * 1.2),
              grid: {
                color: '#e5e7eb',
                drawBorder: false
              },
              ticks: {
                stepSize: Math.max(1, Math.ceil(maxValue / 10)),
                font: {
                  size: 12
                },
                callback: function(value) {
                  return value + '人';  // 添加单位
                }
              }
            }
          },
          interaction: {
            intersect: false,    // 改善悬浮交互
            mode: 'index'
          }
        };
      } catch (error) {
        console.error('Error fetching age distribution:', error);
        ElMessage.error('获取年龄分布数据失败');
      }
    };

    onMounted(async () => {
      await fetchStats();
      await fetchAgeDistribution();
    });

    return {
      stats,
      chartData,
      chartOptions,
    };
  },
};
</script>

<style scoped>
.UserHome {
  padding: 20px;
}

.stats {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
}

.stat {
  background: #f5f5f5;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  width: 23%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease; /* 添加悬停动画效果 */
}

.stat:hover {
  transform: translateY(-5px); /* 悬停时略微上移 */
}

.stat h3 {
  margin-bottom: 10px;
  color: #333;
  font-size: 16px;
}

.stat p {
  font-size: 28px;
  font-weight: bold;
  color: #333;
}

.content-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 20px;  /* 减小上边距 */
}

.chart-wrapper {
  height: 450px;     /* 适当减小高度 */
  width: 100%;        /* 修改为100%宽度 */
  background: white;
  padding: 20px;     /* 减小内边距 */
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
</style>
