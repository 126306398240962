// useBaseDataOperations.js
import { ref, computed } from 'vue';
import { ElMessage, ElMessageBox } from 'element-plus';
import { useStore } from 'vuex';
import * as XLSX from "xlsx";

export function useBaseDataOperations(config, service) {
    const store = useStore();
    const oid = computed(() => store.getters.getOid);

    // 验证 OID
    if (!oid.value) {
        console.error('OID is not defined');
        ElMessage.error('OID 未定义');
        return;
    }

    const tableData = ref([]);
    const searchKeyword = ref('');
    const selectedRows = ref([]);
    const formData = ref({});
    const dialogVisible = ref(false);

    // 添加 searchEmployees 方法
    const searchEmployees = async (query) => {
        try {
            const res = await service.searchEmployees(oid.value, query);
            return res.data;
        } catch (err) {
            console.error('Employee search failed:', err);
            ElMessage.error('搜索失败');
            throw err;
        }
    };

    const fetchData = async () => {
        try {
            const res = await service.fetchData(oid.value);
            tableData.value = res.data;
        } catch (err) {
            console.error('Fetching data failed:', err);
            ElMessage.error('获取数据失败');
        }
    };

    const handleDelete = async (ids) => {
        try {
            await service.deleteData(oid.value, ids);
            ElMessage.success('删除成功');
            tableData.value = tableData.value.filter(item => !ids.includes(item.id));

        } catch (error) {
            ElMessage.error('删除失败');
        }
    };



    const handleExport = async (filename, fieldMapping) => {
        try {
            if (tableData.value.length === 0) {
                // 如果没有数据，只导出表头
                const headers = Object.keys(fieldMapping);
                const worksheet = XLSX.utils.aoa_to_sheet([headers]);
                const workbook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(workbook, worksheet, '员工列表');
                XLSX.writeFile(workbook, filename + '.xlsx');
            } else {
                // 有数据时的导出逻辑
                const data = tableData.value.map(item => {
                    const exportItem = {};
                    Object.keys(fieldMapping).forEach(key => {
                        let value = item[fieldMapping[key]];
                        if (fieldMapping[key] === 'status') {
                            value = value ? '在职' : '离职';
                        }
                        exportItem[key] = value;
                    });
                    return exportItem;
                });

                const worksheet = XLSX.utils.json_to_sheet(data);
                const workbook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(workbook, worksheet, '员工列表');
                XLSX.writeFile(workbook, filename + '.xlsx');
            }
            ElMessage.success('导出成功');
        } catch (error) {
            console.error('Export failed:', error);
            ElMessage.error('导出失败');
        }
    };


    const handleSearch = async () => {
        try {
            const res = await service.searchData(oid.value, searchKeyword.value);
            tableData.value = res.data;
        } catch (err) {
            console.error('Searching failed:', err);
            ElMessage.error('搜索失败');
        }
    };

    const handleSelectionChange = (val) => {
        selectedRows.value = val;
    };



    const handleSizeChange = (size) => {
        fetchData(oid.value);
    };

    const handleDialogClose = () => {
        dialogVisible.value = false;
        formData.value = {};
    };

    // 添加处理数据的方法
    const processFormData = (data, mode = 'add') => {
        const processed = { ...data };
        
        // 设置基础字段
        if (mode === 'add') {
            processed.oid = oid.value;
        }

        // 应用配置中定义的数据处理器
        if (config.dataProcessors) {
            for (const [field, processor] of Object.entries(config.dataProcessors)) {
                if (processor[mode]) {
                    processed[field] = processor[mode](processed);
                }
            }
        }

        return processed;
    };

    // 修改 handleDialogConfirm 方法
    const handleDialogConfirm = async () => {
        try {
            let response;
            const processedData = processFormData(formData.value, formData.value.id ? 'edit' : 'add');
            
            if (processedData.id) {
                response = await service.updateData(oid.value, processedData);
            } else {
                response = await service.addData(oid.value, processedData);
            }
            
            if (response.success) {
                ElMessage.success(processedData.id ? '更新成功' : '添加成功');
                dialogVisible.value = false;
                fetchData();
            } else {
                ElMessage.error(response.message || '操作失败');
            }
        } catch (err) {
            console.error('Operation failed:', err);
            ElMessage.error(err.message || '操作失败');
        }
    };

    // 修改 handleImport 方法
    const handleImport = async (file, fieldMapping) => {
        try {
            const rawData = await readExcelFile(file);
            const headers = rawData[0];

            // 验证表头
            const requiredHeaders = Object.keys(fieldMapping).filter(key => key !== '出生日期'); // 不强制要求出生日期
            const missingHeaders = requiredHeaders.filter(header => !headers.includes(header));
            const extraHeaders = headers.filter(header => !Object.keys(fieldMapping).includes(header));

            if (missingHeaders.length > 0 || extraHeaders.length > 0) {
                let errorMessage = '导入失败：表头不匹配\n\n';
                
                if (missingHeaders.length > 0) {
                    errorMessage += '缺少必需的列：' + missingHeaders.join('\t') + '\n\n';
                }
                
                if (extraHeaders.length > 0) {
                    errorMessage += '存在多余的列：' + extraHeaders.join('\t') + '\n\n';
                }

                errorMessage += '正确的表头格式应为：' + requiredHeaders.join('\t');

                await ElMessageBox.alert(
                    `<div class="import-error-content">${errorMessage.replace(/\n/g, '<br>')}</div>`,
                    '导入错误',
                    {
                        type: 'error',
                        confirmButtonText: '确定',
                        dangerouslyUseHTMLString: true,
                        customClass: 'import-error-dialog',
                        draggable: true
                    }
                );
                return false;
            }

            // 如果表头验证通过，继续处理数据
            const transformedData = rawData.slice(1).map(row => {
                const transformedRow = {};
                Object.keys(fieldMapping).forEach(key => {
                    const columnIndex = headers.indexOf(key);
                    if (columnIndex !== -1) {
                        let value = row[columnIndex];
                        
                        if (typeof fieldMapping[key] === 'object' && fieldMapping[key].transform) {
                            value = fieldMapping[key].transform(value);
                            transformedRow[fieldMapping[key].field] = value;
                        } else {
                            transformedRow[fieldMapping[key]] = value;
                        }
                    }
                });
                return processFormData(transformedRow, 'import');
            });

            console.log('转换后的数据:', transformedData);

            const response = await service.importData(oid.value, transformedData);
            if (response.success) {
                tableData.value = response.data;
                ElMessage.success('导入成功');
                return true;
            } else {
                const message = (response.message || '导入失败，请检查数据格式是否正确').replace(/\n/g, '<br>');
                await ElMessageBox.alert(
                    `<div class="import-error-content">${message}</div>`,
                    '导入失败',
                    {
                        type: 'error',
                        confirmButtonText: '确定',
                        dangerouslyUseHTMLString: true,
                        customClass: 'import-error-dialog',
                        draggable: true,
                        width: '2560px'
                    }
                );
                return false;
            }
        } catch (error) {
            const message = (error.message || '导入失败，请检查文件格式是否正确').replace(/\n/g, '<br>');
            await ElMessageBox.alert(
                `<div class="import-error-content">${message}</div>`,
                '导入失败',
                {
                    type: 'error',
                    confirmButtonText: '确定',
                    dangerouslyUseHTMLString: true,
                    customClass: 'import-error-dialog',
                    draggable: true,
                    width: '2560px'
                }
            );
            console.error('导入失败:', error);
            return false;
        }
    };

// 辅助函数：读取 Excel 文件
    const readExcelFile = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = new Uint8Array(e.target.result);
                const workbook = XLSX.read(data, { type: 'array' });
                const firstSheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[firstSheetName];
                const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 }); // 以数组形式读取数据
                resolve(jsonData); // 返回读取的数据
            };
            reader.onerror = (error) => {
                reject(error);
            };
            reader.readAsArrayBuffer(file); // 读取文件
        });
    };

    const tableRowClassName = ({ row }) => {
        return selectedRows.value.some(selectedRow => selectedRow.id === row.id) ? 'selected-row' : '';
    };



    return {
        tableData,
        searchKeyword,
        selectedRows,
        formData,
        dialogVisible,
        searchEmployees,
        fetchData,
        handleDelete,
        handleImport,
        handleExport,
        handleSearch,
        handleSelectionChange,
        handleSizeChange,
        handleDialogClose,
        handleDialogConfirm,
        tableRowClassName,
        processFormData,
    };
}
