// utils/request.js

import axios from 'axios';
import store from "@/store";

const BASE_URL = '/api';
// const BASE_URL = 'http://localhost:8000';
// const BASE_URL = 'https://www.hblaoxie.com/api';
const request = axios.create({
    baseURL: BASE_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});

// 请求拦截器
request.interceptors.request.use(config => {
    const accessToken = store.state.accessToken; // 从Vuex store中获取accessToken
    if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
});

// 刷新 token 的函数
const refreshToken = async () => {
    const refreshToken = store.state.refreshToken;
    if (!refreshToken) {
        handleTokenError();
        return null;
    }

    try {
        const response = await axios.post(`${BASE_URL}/auth/refresh-token`,
            { token: refreshToken },
            {
                headers: { 'Content-Type': 'application/json' },
                skipAuthRefresh: true
            }
        );

        if (response.data.success) {
            const { accessToken } = response.data.data;
            store.commit('setAccessToken', accessToken);
            return accessToken;
        }
    } catch (error) {
        // console.error('Refresh token error:', error);
        handleTokenError();
    }
    return null;
};

// 处理 token 错误
const handleTokenError = () => {

    store.dispatch('logout'); // 清除Vuex store中的token数据
    window.location.href = '/login';
};

// 响应拦截器
request.interceptors.response.use(
    response => {
        // console.log("响应拦截器response:", response)
        if (response.data.success) {
            return response.data;
        } else {
            throw response.data;
        }
    },
    async error => {
        // console.log("响应拦截器error:", error)
        const originalRequest = error.config;

        if (originalRequest.skipAuthRefresh) {
            throw error;
        }

        if (error.response?.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            try {
                const newAccessToken = await refreshToken();
                if (newAccessToken) {
                    console.log('New token received:', newAccessToken);

                    // 创建新的请求配置
                    const newConfig = {
                        ...originalRequest,
                        headers: {
                            ...originalRequest.headers,
                            'Authorization': `Bearer ${newAccessToken}`
                        }
                    };

                    console.log('New request config:', newConfig);

                    try {
                        // 使用新配置发送请求
                        const retryResponse = await axios(newConfig);
                        console.log('Retry response:', retryResponse);
                        if (retryResponse.data.success) {
                            return retryResponse.data;
                        } else {
                            throw retryResponse.data;
                        }
                    } catch (retryError) {
                        console.error('Retry request failed:', retryError);
                        if (retryError.response?.status === 401) {
                            // 如果重试请求还是401，可能是token过期太快，直接登出
                            handleTokenError();
                        }
                        throw retryError;
                    }
                }
            } catch (refreshError) {
                console.error('Token refresh failed:', refreshError);
                handleTokenError();
                throw refreshError;
            }
        } else if (error.response?.status === 403) {
            handleTokenError();
        }

        throw error;
    }
);

export default request;
