
import request from '@/utils/request';

export default {
    addData(oid, data) {
        return request({
            url: '/worker/add',
            method: 'post',
            data
        });
    },
    updateData(oid,data) {
        return request({
            url: '/worker/update',
            method: 'post',
            data
        });
    },

    fetchData(oid) {
        return request({
            url: '/worker/findRecentChangedData',
            method: 'get',
            params: { oid },
        });
    },

    deleteData(oid,ids) {
        return request({
            url: '/worker/delete',
            method: 'delete',
            data: ids, // `ids` 作为请求体发送
        });
    },

    importData( oid,data) {
        return request({
            url: '/worker/import',
            method: 'post',
            params: { oid },
            data
        });
    },

    searchData(oid, keyword) {
        return request({
            url: '/worker/search',
            method: 'get',
            params: { oid,keyword }
        });
    },

      getHomeStats() {
        return request({
            url: '/adminHome/stats',
            method: 'get'
        });
    },

      getDailyNewWorkers() {
        return request({
            url: '/adminHome/dailynew',
            method: 'get'
        });
    }



};
