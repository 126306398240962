
import request from '@/utils/request';

export default {
    addData(oid, data) {
        return request({
            url: '/account/add',
            method: 'post',
            params:{oid},
            data
        });
    },
    updateData(oid,data) {
        return request({
            url: '/account/update',
            method: 'post',
            params:{oid},
            data
        });
    },

    fetchData(oid) {
        return request({
            url: '/account/findRecentChangedData',
            method: 'get',
            params: { oid }
        });
    },

    deleteData(oid,ids) {
        return request({
            url: '/account/delete',
            method: 'delete',
            params:{oid},
            data: ids, // `ids` 作为请求体发送
        });
    },

    importData( oid,data) {
        return request({
            url: '/account/import',
            method: 'post',

            params: { oid },
            data
        });
    },

    search(oid, keyword) {
        return request({
            url: '/account/searchData',
            method: 'get',
            params: { oid,keyword }
        });
    }
};
