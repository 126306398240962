<template>
  <div>
    <el-menu
        style="width: 150px; min-height: calc(100vh - 50px);"
        :default-active="activeMenu"
    :default-openeds="['1']"
    router
    class="el-menu-vertical-demo"
    active-text-color="#ffd04b"
    background-color="rgb(50,65,87)"
    text-color="#fff"
    >
    <!-- 根据用户角色显示不同的菜单 -->
    <template v-if="isAdmin">
      <el-menu-item index="/admin/home" class="menu-item">
        <div class="icon-container">
          <i class="iconfont icon-home"></i>
        </div>
        <span>首页</span>
      </el-menu-item>
      <el-menu-item index="/admin/company" class="menu-item">
        <div class="icon-container">
          <i class="iconfont icon-company"></i>
        </div>
        <span>会员单位</span>
      </el-menu-item>
      <el-menu-item index="/admin/worker" class="menu-item">
        <div class="icon-container">
          <i class="iconfont icon-wechat"></i>
        </div>
        <span>微信用户</span>
      </el-menu-item>
      <el-menu-item index="/admin/account" class="menu-item">
        <div class="icon-container">
          <i class="iconfont icon-user"></i>
        </div>
        <span>账号管理</span>
      </el-menu-item>
      <el-menu-item index="/admin/about" class="menu-item">
        <div class="icon-container">
          <i class="iconfont icon-icon-about"></i>
        </div>
        <span>关于我们</span>
      </el-menu-item>
    </template>

    <template v-else>
      <el-menu-item index="/user/home" class="menu-item">
        <div class="icon-container">
          <i class="iconfont icon-home"></i>
        </div>
        <span>首页</span>
      </el-menu-item>
      <el-menu-item index="/user/employee" class="menu-item">
        <div class="icon-container">
          <i class="iconfont icon-user"></i>
        </div>
        <span>员工表</span>
      </el-menu-item>
      <el-menu-item index="/user/workday" class="menu-item">
        <div class="icon-container">
          <i class="iconfont icon-workday"></i>
        </div>
        <span>工作量</span>
      </el-menu-item>
      <el-menu-item index="/user/payment" class="menu-item">
        <div class="icon-container">
          <i class="iconfont icon-money"></i>
        </div>
        <span>工资款</span>
      </el-menu-item>
<!--      <el-menu-item index="/user/settlement" class="menu-item">-->
<!--        <div class="icon-container">-->
<!--          <i class="iconfont icon-checkBill"></i>-->
<!--        </div>-->
<!--        <span>对账单</span>-->
<!--      </el-menu-item>-->
    </template>
    </el-menu>
  </div>
</template>

<script>
export default {
  name: "SideBar",
  data() {
    return {
      activeMenu: this.$route.path // 初始化为当前路由的路径
    };
  },
  computed: {
    // 从 localStorage 获取当前用户类型
    isAdmin() {
      const user = JSON.parse(localStorage.getItem('userInfo'));
      return user && user.userType === 'admin';
    }
  },
  watch: {
    '$route'(to) {
      this.activeMenu = to.path; // 路由变化时更新选中状态
    }
  }
}
</script>

<style scoped>
.menu-item {
  display: flex;
  align-items: center;
}

.icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px; /* 根据需要调整间距 */
  font-size: 32px; /* 调整图标大小 */
}
</style>
