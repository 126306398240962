<template>
  <div className="chart-container">
    <canvas ref="canvas"></canvas>
  </div>
</template>

<script>
import {ref, onMounted, watch} from 'vue'
import {Chart, registerables} from 'chart.js'

Chart.register(...registerables)

export default {
  name: 'LineChart',
  props: {
    chartData: {
      type: Object,
      required: true
    },
    options: {
      type: Object,
      required: false,
      default: () => ({
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            display: true,
            title: {
              display: true,
              // text: '日期'
            }
          },
          y: {
            display: true,
            title: {
              display: true,
              // text: '人数'
            }
          }
        },
        elements: {
          line: {
            tension: 0.4
          }
        }
      })
    }
  },
  setup(props) {
    const canvas = ref(null)
    let chartInstance = null

    onMounted(() => {
      chartInstance = new Chart(canvas.value, {
        type: 'line',
        data: props.chartData,
        options: props.options
      })
    })

    watch(() => props.chartData, (newData) => {
      if (chartInstance) {
        chartInstance.data = newData
        chartInstance.update()
      }
    })

    return {
      canvas
    }
  }
}
</script>

<style scoped>
.chart-container {
  position: relative;
  width: 100%;
  height: 100%;
}

canvas {
  display: block;
  width: 100%;
  height: 100%;
}
</style>
