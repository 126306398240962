// src/configs/accountViewConfig.js

import {formatDate, formatDateTime} from '@/utils/dateUtil';
import { ElMessage } from 'element-plus';
export const accountViewConfig = {
    buttons: [
        {
            text: '新增',
            type: 'primary',
            handler: (context) => {
                context.formData = {};
                context.dialogVisible = true;
            }
        },
        {
            text: '编辑',
            type: 'primary',
            handler: (context) => {
                if (context.selectedRows.length === 1) {
                    context.formData = { ...context.selectedRows[0] };
                    context.dialogVisible = true;
                } else {
                    ElMessage.warning('请选择一条记录进行编辑');
                }
            },
            disabled: (context) => context.selectedRows.length !== 1
        },
        {
            text: '删除',
            type: 'danger',
            handler: async (context) => {
                if (context.selectedRows.length === 0) {
                    ElMessage.warning('请选择要删除的记录');
                    return;
                }
                const ids = context.selectedRows.map(row => row.id);
                await context.handleDelete(ids);
            },
            disabled: (context) => context.selectedRows.length === 0
        },

        {
            text: '刷新',
            type: 'primary',
            handler: (context) => {
                context.fetchData();
            }
        }
    ],
    columns: [
        { prop: 'organization', label: '单位名称', width: '300', type: 'string' },
        { prop: 'oid', label: '单位编号', width: '100', type: 'number' },
        { prop: 'username', label: '账号', width: '120', type: 'string' },
        { prop: 'realName', label: '姓名', width: '120', type: 'string' },

        {
            prop: 'createTime',
            label: '创建时间',
            width: '170',
            type: 'date',
            formatter: (row) => {
                return formatDateTime(row.createTime);
            }
        },
        {
            prop: 'reviseTime',
            label: '修改时间',
            width: '170',
            type: 'date',
            formatter: (row) => {
                return formatDateTime(row.reviseTime);
            }
        }
    ],
    selectable: true,
    searchPlaceholder: '请输入公司名称|负责人',
    dialog: {
        title: '编辑',
        width: '40%',
        fields: [
            { prop: 'organization', label: '单位名称', type: 'input', required: true,span:24 },
            { prop: 'oid', label: '单位编号', type: 'input', disabled: false,span:24  },
            { prop: 'username', label: '登录账号', type: 'input', required: true ,span:24},
            { prop: 'realName', label: '中文名称', type: 'input', disabled: false ,span:24},

        ]

    },
    rules: {
        organization: [
            { required: true, message: '请输入单位名称', trigger: 'blur' },
            { min: 2, max: 20, message: '长度在2-20个字符之间', trigger: 'blur' }
        ],
        oid: [
            { required: true, message: '请输入单位编号', trigger: 'blur' },
            { pattern: /^\d{1,10}$/, message: '单位编号必须是数字', trigger: 'blur' }
        ],
        username: [
            { required: true, message: '请输入登录账号名称', trigger: 'blur' }
        ],
        realName: [
            { required: true, message: '请输入账号中文名称', trigger: 'blur' }
        ],

    },
};

export default accountViewConfig;
