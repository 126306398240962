<template>
  <div>
    <router-view/>
  </div>

</template>
<script>

export default {
  name: 'app'
}

</script>
<style>
/*@import url("//unpkg.com/element-ui@2.15.6/lib/theme-chalk/index.css");*/
</style>
