<!-- WorkdayView.vue -->
<template>
  <BaseDataView :config="config" :service="combinedService" />
</template>

<script setup>
import BaseDataView from '@/components/BaseDataView.vue';
import config from '@/configs/workdayViewConfig';
import service from '@/api/workdayService';
import employeeService from '@/api/employeeService';

const combinedService = {
  ...service,
  employeeService
};
</script>
