<template>
  <div class="login-container">
    <el-card class="login-card">
      <template v-slot:header>
        <div class="login-header">
          <h2>湖北省建筑劳务协会</h2>
        </div>
      </template>
      <el-form 
        ref="loginFormRef"
        :model="loginForm" 
        :rules="rules" 
        label-width="100px" 
        class="login-form"
      >
        <el-form-item label="用户名" prop="username">
          <el-input v-model="loginForm.username" placeholder="请输入用户名" ref="usernameInput" @keydown.enter="focusPassword"></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input type="password" v-model="loginForm.password" placeholder="请输入密码" ref="passwordInput" @keydown.enter="submitForm"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" style="width: 100%; padding: 10px 30px;" @click="submitForm">登录</el-button>
        </el-form-item>
      </el-form>
      <el-alert v-if="loginFailed" show-icon closable :title="loginErrorMessage" type="error" @close="loginFailed = false" center></el-alert>
    </el-card>
  </div>
</template>

<script setup>
import { ref, reactive, onMounted, getCurrentInstance } from 'vue';
import { useRouter } from 'vue-router';
import userService from '@/api/user';

const router = useRouter();
const loginForm = reactive({
  username: '',
  password: ''
});

const loginFormRef = ref(null);
const usernameInput = ref(null);
const passwordInput = ref(null);
const loginFailed = ref(false);
const loginErrorMessage = ref('');

const rules = {
  username: [
    { required: true, message: '请输入用户名', trigger: 'blur' }
  ],
  password: [
    { required: true, message: '请输入密码', trigger: 'blur' }
  ]
};

onMounted(() => {
  // 页面加载完毕后，将焦点定位到用户名输入框
  usernameInput.value.focus();
  // 打印 vue 实例
  console.log('Current instance:', getCurrentInstance());
});

const submitForm = async () => {
  if (!loginFormRef.value) return;
  
  try {
    await loginFormRef.value.validate();
    const userType = await userService.login(loginForm);
    if (userType === 'admin') {
      router.push('/admin/home');
    } else if (userType === 'user') {
      router.push('/user/home');
    }
  } catch (error) {
    loginFailed.value = true;
    loginErrorMessage.value = '账号或密码不正确，请重新输入。';
    console.error('Login failed:', error);
  }
};

const focusPassword = () => {
  passwordInput.value.focus();
};
</script>

<style scoped>
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.login-card {
  width: 500px;
}

.login-header {
  text-align: center;
  color: dodgerblue;
}

.login-form {
  margin-top: 30px;
}

.login-button {
  width: 100%;
  text-align: center;
}
</style>
