<template>
  <div class="admin-layout">
    <!-- 顶部标题栏 -->
    <HeaderBar class="header-bar" />

    <div class="main-container">
      <!-- 引入 SideBar 组件 -->
      <SideBar role="admin" class="sidebar"/>

      <!-- 右侧内容区域 -->
      <main class="content">
        <router-view></router-view> <!-- 用于展示子路由内容 -->
      </main>
    </div>
  </div>
</template>

<script>
import HeaderBar from '@/components/HeaderBar.vue';
import SideBar from '@/components/SideBar.vue';

export default {
  components: {
    HeaderBar,
    SideBar,
  },
};
</script>

<style scoped>
.admin-layout {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  margin: 0;
  padding: 0;
}

.header-bar {
  width: 100%;
}

.main-container {
  display: flex;
  flex: 1;
  overflow: hidden;
}

.sidebar {
  /*width: 200px;*/
  background-color: #f2f2f2;
  height: 100%;
  border-right: 1px solid #e0e0e0;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
}
</style>
