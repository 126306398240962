// src/configs/employeeViewConfig.js

import {formatDate, formatDateTime} from '@/utils/dateUtil';
import { ElMessage } from 'element-plus';
export const employeeViewConfig = {
    buttons: [
        {
            text: '新增',
            type: 'primary',
            handler: (context) => {
                context.formData = {};
                context.dialogVisible = true;
            }
        },
        {
            text: '编辑',
            type: 'primary',
            handler: (context) => {
                if (context.selectedRows.length === 1) {
                    context.formData = { ...context.selectedRows[0] };
                    context.dialogVisible = true;
                } else {
                    ElMessage.warning('请选择一条记录进行编辑');
                }
            },
            disabled: (context) => context.selectedRows.length !== 1
        },
        {
            text: '删除',
            type: 'danger',
            handler: async (context) => {
                if (context.selectedRows.length === 0) {
                    ElMessage.warning('请选择要删除的记录');
                    return;
                }
                const ids = context.selectedRows.map(row => row.id);
                await context.handleDelete(ids);
            },
            disabled: (context) => context.selectedRows.length === 0
        },
        {
            text: '导入',
            type: 'primary',
            handler: async (context) => {
                const fieldMapping = {
                    '工号': 'emid',
                    '姓名': 'name',
                    '性别': 'gender',
                    '班组': 'teamgroupName',
                    '手机号': 'mobile',
                    '身份证': 'idcard',
                    '状态': {
                        field: 'status',
                        transform: (value) => {
                            if (typeof value === 'string') {
                                return value === '在职';
                            }
                            return value;
                        }
                    },
                    '出生日期': {
                        field: 'birthday',
                        transform: (value) => {
                            if (!value) return null;
                            try {
                                if (value instanceof Date) {
                                    return value.toISOString().split('T')[0];
                                }
                                if (typeof value === 'string') {
                                    const date = new Date(value);
                                    if (!isNaN(date.getTime())) {
                                        return date.toISOString().split('T')[0];
                                    }
                                }
                                if (typeof value === 'number') {
                                    const date = new Date((value - 25569) * 86400 * 1000);
                                    return date.toISOString().split('T')[0];
                                }
                                return value;
                            } catch (error) {
                                console.error('日期转换错误:', error);
                                return value;
                            }
                        }
                    },
                    '住址': 'address'
                }

                const fileInput = document.createElement('input');
                fileInput.type = 'file';
                fileInput.accept = '.xlsx, .xls';
                fileInput.onchange = async (event) => {
                    const file = event.target.files[0];
                    context.handleImport(file, fieldMapping);
                };
                fileInput.click();
            }
        },
        {
            text: '导出',
            type: 'success',
            handler: async (context) => {
                const fieldMapping = {
                    '工号': 'emid',
                    '姓名': 'name',
                    '性别': 'gender',
                    '状态': 'status',
                    '班组': 'teamgroupName',
                    '手机号': 'mobile',
                    '身份证': 'idcard',
                    '出生日期': 'birthday',
                    '住址': 'address',
                }
                context.handleExport("员工表", fieldMapping);
            }
        },
        {
            text: '刷新',
            type: 'primary',
            handler: (context) => {
                context.fetchData();
            }
        }
    ],
    columns: [
        { prop: 'emid', label: '工号', width: '80', type: 'number' },
        { prop: 'name', label: '姓名', width: '80', type: 'string' },
        { prop: 'gender', label: '性别', width: '60', type: 'string' },
        { 
            prop: 'status', 
            label: '状态', 
            width: '80', 
            type: 'string',
            formatter: (row) => {
                return row.status ? '在职' : '离职';
            }
        },
        { prop: 'teamgroupName', label: '班组', width: '140', type: 'string' },
        { prop: 'mobile', label: '手机号', width: '120', type: 'string' },
        { prop: 'idcard', label: '身份证', width: '180', type: 'string' },
        {
            prop: 'birthday',
            label: '出生日期',
            width: '120',
            type: 'date',
            formatter: (row) => {
                return formatDate(row.birthday);
            }
        },
        { prop: 'address', label: '住址', width: '300', type: 'string' },
        {
            prop: 'createTime',
            label: '创建时间',
            width: '170',
            type: 'date',
            formatter: (row) => {
                return formatDateTime(row.createTime);
            }
        },
        {
            prop: 'reviseTime',
            label: '修改时间',
            width: '170',
            type: 'date',
            formatter: (row) => {
                return formatDateTime(row.reviseTime);
            }
        },
 
    ],
    selectable: true,
    searchPlaceholder: '姓名|班组',
    dialog: {
        title: '编辑',
        width: '60%',
        fields: [
            { prop: 'emid', label: '工号', type: 'input', span: 6, required: true },
            { prop: 'name', label: '姓名', type: 'input', span: 6, disabled: false, required: true },
            { 
                prop: 'gender', 
                label: '性别', 
                type: 'select', 
                span: 6, 
                disabled: false,  
                required: true,
                options: [
                    { value: '男', label: '男' },
                    { value: '女', label: '女' }
                ],
                props: {
                    placeholder: '请选择性别'
                }
            },
            { 
                prop: 'status', 
                label: '状态', 
                type: 'select', 
                span: 6, 
                required: true,
                options: [
                    { value: true, label: '在职' },
                    { value: false, label: '离职' }
                ],
                props: {
                    placeholder: '请选择状态'
                }
            },
            { prop: 'teamgroupName', label: '班组', type: 'input', span: 12, disabled: false, required: true },
            { prop: 'mobile', label: '手机号', type: 'input', span: 12, disabled: false, required: true },
            { 
                prop: 'idcard', 
                label: '身份证号', 
                type: 'input', 
                span: 12, 
                required: true,
                props: {
                    onChange: (value, formData) => {
                        if (!formData) return;
                        if (value && value.length === 18) {
                            // 从身份证提取出生日期并格式化为 yyyy-mm-dd
                            const year = value.substring(6, 10);
                            const month = value.substring(10, 12);
                            const day = value.substring(12, 14);
                            // 确保使用 - 作为分隔符
                            formData.birthday = `${year}-${month}-${day}`;
                        }
                    }
                }
            },
            { 
                prop: 'birthday', 
                label: '出生日期', 
                type: 'date', 
                span: 12, 
                required: true,
                props: {
                    valueFormat: 'YYYY-MM-DD',  // 确保日期格式为 yyyy-mm-dd
                    format: 'YYYY-MM-DD'        // 显示格式也设置为 yyyy-mm-dd
                }
            },
            { prop: 'address', label: '地址', type: 'input', span: 24, required: true },
           
        ]
    },
    rules: {

        emid: [
            { required: true, message: '请输入工号', trigger: 'blur' },
            { pattern: /^\d{1,10}$/, message: '工号必须是数字', trigger: 'blur' }
        ],
        name: [
            { required: true, message: '请输入姓名', trigger: 'blur' },
            { min: 2, max: 20, message: '姓名长度在2-20个字符之间', trigger: 'blur' }
        ],
        teamgroupName: [
            { required: true, message: '请输入班组名称', trigger: 'blur' }
        ],
        mobile: [
            { required: true, message: '请输入手机号', trigger: 'blur' }
        ],
        birthday: [
            { required: true, message: '请选择日期', trigger: 'change' }
        ],
        idcard: [
            { required: true, message: '请输入身份证号', trigger: 'blur' },
            { 
                pattern: /^[1-9]\d{5}(19|20)\d{2}(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01])\d{3}[\dX]$/,
                message: '请输入正确的身份证号',
                trigger: 'blur'
            }
        ],
        gender: [
            { required: true, message: '请选择性别', trigger: 'change' }
        ],
        status: [
            { required: true, message: '请选择状态', trigger: 'change' }
        ],

    },
    dataProcessors: {
        birthday: {
            import: (data) => {
                // 从身份证提取生日
                if (!data.idcard || data.idcard.length !== 18) {
                    return data.birthday || null;
                }

                const idCardBirthday = `${data.idcard.substring(6, 10)}-${data.idcard.substring(10, 12)}-${data.idcard.substring(12, 14)}`;
                
                // 如果Excel中有生日，进行验证
                if (data.birthday) {
                    if (data.birthday !== idCardBirthday) {
                        throw new Error(`员工 ${data.name}(${data.emid}) 的出生日期与身份证不符：\n身份证显示: ${idCardBirthday}\n填写日期: ${data.birthday}`);
                    }
                    return data.birthday;
                }
                
                // 如果没有提供生日，使用身份证中的日期
                return idCardBirthday;
            }
        },
        // ... other processors ...
    },
};

export default employeeViewConfig;
