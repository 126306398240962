// src/configs/workdayViewConfig.js

import {formatDate, formatDateTime} from '@/utils/dateUtil';
import { ElMessage } from 'element-plus';
export const workdayViewConfig = {
    buttons: [
        {
            text: '新增',
            type: 'primary',
            handler: (context) => {
                context.formData = {};
                context.dialogVisible = true;
            }
        },
        {
            text: '编辑',
            type: 'primary',
            handler: (context) => {
                if (context.selectedRows.length === 1) {
                    context.formData = { ...context.selectedRows[0] };
                    context.dialogVisible = true;
                } else {
                    ElMessage.warning('请选择一条记录进行编辑');
                }
            },
            disabled: (context) => context.selectedRows.length !== 1
        },
        {
            text: '删除',
            type: 'danger',
            handler: async (context) => {
                if (context.selectedRows.length === 0) {
                    ElMessage.warning('请选择要删除的记录');
                    return;
                }
                const ids = context.selectedRows.map(row => row.id);
                await context.handleDelete(ids);
            },
            disabled: (context) => context.selectedRows.length === 0
        },
        {
            text: '导入',
            type: 'primary',
            handler: async (context) => {
                const fieldMapping = {
                  
                    '工号': 'emid',
                    '姓名': 'name',
                    '班组名称': 'teamgroupName',
                    '考勤日期': {
                        field: 'workDate',
                        transform: (value) => {
                            console.log('原始日期值:', value, 'type:', typeof value);
                            
                            // 如果是空值，直接返回
                            if (!value) {
                                return null;
                            }

                            try {
                                // 如果是 Date 对象
                                if (value instanceof Date) {
                                    console.log('Date对象转换结果:', value.toISOString().split('T')[0]);
                                    return value.toISOString().split('T')[0];
                                }

                                // 如果是字符串，尝试直接解析
                                if (typeof value === 'string') {
                                    const date = new Date(value);
                                    if (!isNaN(date.getTime())) {
                                        console.log('字符串转换结果:', date.toISOString().split('T')[0]);
                                        return date.toISOString().split('T')[0];
                                    }
                                }

                                // 如果是数字（Excel序列号）
                                if (typeof value === 'number') {
                                    const date = new Date((value - 25569) * 86400 * 1000);
                                    console.log('数字转换结果:', date.toISOString().split('T')[0]);
                                    return date.toISOString().split('T')[0];
                                }

                                console.log('无法处理的日期格式:', value);
                                return value;
                            } catch (error) {
                                console.error('日期转换错误:', error);
                                return value;
                            }
                        }
                    },
                  
                    '项目名称': 'projectName',
                    '点工数目': 'diangongNumber',
                    '点工工价': 'diangongPrice',
                    '点工金额': 'diangongMoney',
                    '包工金额': 'baogongMoney',
                    '备注': 'description',
                }

                const fileInput = document.createElement('input');
                fileInput.type = 'file';
                fileInput.accept = '.xlsx, .xls';
                fileInput.onchange = async (event) => {
                    const file = event.target.files[0];
                    context.handleImport(file, fieldMapping);
                };
                fileInput.click();
            }
        },
        {
            text: '导出',
            type: 'success',
            handler: async (context) => {
                const fieldMapping = {
                    '工号': 'emid',
                    '姓名': 'name',
                    '班组名称': 'teamgroupName',
                    '考勤日期': 'workDate',
                  
                    '项目名称': 'projectName',
                    '点工数目': 'diangongNumber',
                    '点工工价': 'diangongPrice',
                    '点工金额': 'diangongMoney',
                    '包工金额': 'baogongMoney',
                    '备注': 'description',
                }
                context.handleExport("工作量", fieldMapping);
            }
        },
        {
            text: '刷新',
            type: 'primary',
            handler: (context) => {
                context.fetchData();
            }
        }
    ],
    columns: [
      
        { prop: 'emid', label: '工号', width: '80', type: 'number' },
        { prop: 'name', label: '姓名', width: '80', type: 'string' },
        { prop: 'teamgroupName', label: '班组名称', width: '120', type: 'string' },
        {
            prop: 'workDate',
            label: '考勤日期',
            width: '120',
            type: 'date',
            formatter: (row) => {
                return formatDate(row.workDate);
            }
        },
       
        { prop: 'projectName', label: '项目名称', width: '160', type: 'string' },
        {
            prop: 'baogongMoney',
            label: '包工金额',
            width: '120',
            type: 'number',
            formatter: (row) => {
                return parseFloat(row.baogongMoney || 0).toFixed(2);
            }
        },
        {
            prop: 'diangongNumber',
            label: '点工数目',
            width: '120',
            type: 'number',
            formatter: (row) => {
                return parseFloat(row.diangongNumber || 0).toFixed(2);
            }
        },
        {
            prop: 'diangongPrice',
            label: '点工工价',
            width: '120',
            type: 'number',
            formatter: (row) => {
                return parseFloat(row.diangongPrice || 0).toFixed(2);
            }
        },
        {
            prop: 'diangongMoney',
            label: '点工金额',
            width: '120',
            type: 'number',
            formatter: (row) => {
                return parseFloat(row.diangongMoney || 0).toFixed(2);
            }
        },
        { prop: 'description', label: '备注', width: '300', type: 'string' },
        {
            prop: 'createTime',
            label: '创建时间',
            width: '170',
            type: 'date',
            formatter: (row) => {
                return formatDateTime(row.createTime);
            }
        },
        {
            prop: 'reviseTime',
            label: '修改时间',
            width: '170',
            type: 'date',
            formatter: (row) => {
                return formatDateTime(row.reviseTime);
            }
        }
    ],
    selectable: true,
    searchPlaceholder: '姓名|班组',
    dialog: {
        title: '编辑',
        width: '60%',
        fields: [
         

            {
                prop: 'emid',
                label: '工号',
                type: 'autocomplete',
                span: 8,
                required: true,
                props: {
                    placeholder: '请输入工号或姓名',
                    valueKey: 'emid',
                    onSearch: async (query, context) => {
                        console.log('搜索关键字:', query);
                        if (query) {
                            try {
                                const res = await context.searchEmployees(query);
                                console.log('搜索结果:', res);
                                return Array.isArray(res) ? res : (res?.data || []).map(item => ({
                                    value: item.emid,
                                    name: item.name,
                                    emid: item.emid,
                                    teamgroupName: item.teamgroupName,
                                    address: item.address
                                }));
                            } catch (error) {
                                console.error('搜索失败:', error);
                                return [];
                            }
                        }
                        return [];
                    },
                    onSelect: (item, context) => {
                        console.log('选中项:', item);
                        context.formData.emid = item.emid;
                        context.formData.name = item.name;
                        context.formData.teamgroupName = item.teamgroupName;

                        setTimeout(() => {
                            const projectNameField = context.formRef.value?.fields?.projectName;
                            if (projectNameField?.$el?.querySelector('input')) {
                                projectNameField.$el.querySelector('input').focus();
                            }
                        }, 0);
                    }
                }
            },


            { prop: 'name', label: '姓名', type: 'input', span: 8, disabled: false,  required: true, },
           
            { prop: 'teamgroupName', label: '班组名称', type: 'input', span: 8, disabled: false,  required: true, },
            { prop: 'workDate', label: '考勤日期', type: 'date', span: 8, required: true },
            { prop: 'projectName', label: '项目名称', type: 'input', span: 8, disabled: false,  required: true, },
            {
                prop: 'baogongMoney',
                label: '包工金额',
                type: 'number',
                span: 8,
                required: false,
                props: {
                    precision: 2,
                    controls: false
                }
            },
            {
                prop: 'diangongNumber',
                label: '点工数目',
                type: 'number',
                span: 8,
                required: false,
                props: {
                    precision: 2,
                    onChange: (value, formData) => {
                        console.log('点工数目改变:', value, formData);
                        if (!formData || typeof formData !== 'object') return;
                        const price = parseFloat(formData.diangongPrice) || 0;
                        formData.diangongMoney = (parseFloat(value || 0) * price).toFixed(2);
                    }
                }
            },
            {
                prop: 'diangongPrice',
                label: '点工工价',
                type: 'number',
                span: 8,
                required: false,
                props: {
                    precision: 2,
                    onChange: (value, formData) => {
                        console.log('点工工价改变:', value, formData);
                        if (!formData || typeof formData !== 'object') return;
                        const number = parseFloat(formData.diangongNumber) || 0;
                        formData.diangongMoney = (parseFloat(value || 0) * number).toFixed(2);
                    }
                }
            },
            {
                prop: 'diangongMoney',
                label: '点工金额',
                type: 'number',
                span: 8,
                required: false,
                props: {
                    precision: 2,
                    onChange: (value, formData) => {
                        console.log('点工金额改变:', value, formData);
                        if (!formData || typeof formData !== 'object') return;
                        const number = parseFloat(formData.diangongNumber) || 0;
                        if (number > 0) {
                            formData.diangongPrice = (parseFloat(value || 0) / number).toFixed(2);
                        }
                    }
                }
            },
            { prop: 'description', label: '备注', type: 'textarea', span: 24, required: true },
        ]
    },
    rules: {
    
        emid: [
            { required: true, message: '请输入工号', trigger: 'blur' },
            { pattern: /^\d{1,10}$/, message: '工号必须是数字', trigger: 'blur' }
        ],
        name: [
            { required: true, message: '请输入姓名', trigger: 'blur' },
            { min: 2, max: 20, message: '姓名长度在2-20个字符之间', trigger: 'blur' }
        ],
        workDate: [
            { required: true, message: '请选择考勤日期', trigger: 'change' }
        ],
        teamgroupName: [
            { required: true, message: '请输入班组名称', trigger: 'blur' }
        ],
        projectName: [
            { required: true, message: '请输入项目名称', trigger: 'blur' }
        ],
        diangongNumber: [
            { pattern: /^\d+(\.\d{1,2})?$/, message: '请输入正确的数字格式，最多保留两位小数', trigger: 'blur' }
        ],
        diangongPrice: [
            { pattern: /^\d+(\.\d{1,2})?$/, message: '请输入正确的数字格式，最多保留两位小数', trigger: 'blur' }
        ],
        diangongMoney: [
            { pattern: /^\d+(\.\d{1,2})?$/, message: '请输入正确的数字格式，最多保留两位小数', trigger: 'blur' }
        ],
        baogongMoney: [
            { pattern: /^\d+(\.\d{1,2})?$/, message: '请输入正确的数字格式，最多保留两位小数', trigger: 'blur' }
        ],

    },
};

export default workdayViewConfig;
