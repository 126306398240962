<template>
  <div>
    <header-bar :showUserInfo="false">
      <template v-slot:left>
        <button @click="goBack" class="back-button">返回</button>
      </template>
      <template v-slot:title>
        <h1 class="header-title">账户设置</h1>
      </template>
    </header-bar>

    <div class="user-profile-container">
      <div class="profile-card">
        <h3 class="section-title">基本资料</h3>
        <el-form label-width="100px" class="profile-form">
          <el-form-item label="用户账号">
            <el-input v-model="username" disabled class="form-input"></el-input>
          </el-form-item>
          <el-form-item label="用户密码">
            <el-input
              value="******"
              disabled
              class="form-input"
              style="width: calc(100% - 90px)"
            ></el-input>
            <el-button
              type="primary"
              style="position: absolute; right: 0; top: 0; bottom: 0;"
              @click="showPasswordDialog = true"
            >
              修改密码
            </el-button>
          </el-form-item>
          <el-form-item label="真实姓名">
            <el-input v-model="realName" disabled class="form-input"></el-input>
          </el-form-item>
          <el-form-item label="单位名称">
            <el-input v-model="organization" disabled class="form-input"></el-input>
          </el-form-item>
          <el-form-item label="单位编号">
            <el-input v-model="oid" disabled class="form-input"></el-input>
          </el-form-item>
        </el-form>
      </div>
    </div>

    <el-dialog
      title="修改密码"
      v-model="showPasswordDialog"
      width="400px"
      :close-on-click-modal="false"
    >
      <el-form
        ref="passwordFormRef"
        :model="passwordForm"
        :rules="passwordRules"
        label-width="80px"
      >
        <el-form-item label="当前密码" prop="currentPassword">
          <el-input
            v-model="passwordForm.currentPassword"
            type="password"
            show-password
          ></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="newPassword">
          <el-input
            v-model="passwordForm.newPassword"
            type="password"
            show-password
          ></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="confirmPassword">
          <el-input
            v-model="passwordForm.confirmPassword"
            type="password"
            show-password
          ></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="showPasswordDialog = false">取消</el-button>
          <el-button type="primary" @click="handleChangePasswordSubmit">
            应用
          </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import { ref, reactive, computed } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import HeaderBar from '@/components/HeaderBar.vue'
import {ElMessage, ElMessageBox} from "element-plus";
import  userService from "@/api/user";

// 路由相关
const router = useRouter()
const goBack = () => router.go(-1)

// Store 相关
const store = useStore()
const realName = computed(() => store.state.userInfo.realName)
const username = computed(() => store.state.userInfo.username)
const oid = computed(() => store.state.userInfo.oid)
const organization = computed(() => store.state.userInfo.organization)

// 密码对话框相关
const showPasswordDialog = ref(false)
const passwordFormRef = ref(null)

const passwordForm = reactive({
  currentPassword: '',
  newPassword: '',
  confirmPassword: ''
})

// 密码验证规则
const validateConfirmPassword = (rule, value, callback) => {
  if (value !== passwordForm.newPassword) {
    callback(new Error('两次输入的密码不一致'))
  } else {
    callback()
  }
}

const passwordRules = {
  currentPassword: [
    { required: true, message: '请输入当前密码', trigger: 'blur' }
  ],
  newPassword: [
    { required: true, message: '请输入新密码', trigger: 'blur' },
    { min: 6, message: '密码长度不能小于6位', trigger: 'blur' }
  ],
  confirmPassword: [
    { required: true, message: '请确认新密码', trigger: 'blur' },
    { validator: validateConfirmPassword, trigger: 'blur' }
  ]
}

// 方法
const handleChangePasswordSubmit = async () => {
  try {
    await passwordFormRef.value.validate()
    const result = await userService.changePassword(passwordForm)
    showPasswordDialog.value = false
    resetPasswordForm()

    // 显示倒计时对话框
    let seconds = 5
    ElMessageBox.alert(
      `密码修改成功，请重新登录`,
      '提示',
      {
        confirmButtonText: `确定(${seconds}s)`,
        showClose: false,
        closeOnClickModal: false,
        closeOnPressEscape: false,
        beforeClose: (action, instance, done) => {
          handleLogout()
          done()
        }
      }
    )

    // 倒计时更新
    const timer = setInterval(() => {
      seconds--
      if (seconds <= 0) {
        clearInterval(timer)
        ElMessageBox.close()
        handleLogout()
      } else {
        // 更新确定按钮文本
        document.querySelector('.el-message-box__btns button').textContent =
          `确定(${seconds}s)`
      }
    }, 1000)

  } catch (error) {
    console.error('修改密码失败:', error)
    ElMessage.error(error.message || '修改密码失败')
  }
}

const resetPasswordForm = () => {
  passwordForm.currentPassword = ''
  passwordForm.newPassword = ''
  passwordForm.confirmPassword = ''
  passwordFormRef.value?.resetFields()
}

// 添加退出登录方法
const handleLogout = async () => {
  await store.dispatch('logout')
  router.push('/login')
}

// 生命周期
// onMounted(() => {
//   store.dispatch('loadUserInfo')
// })
</script>

<style scoped>
.user-profile-container {
  max-width: 800px;
  margin: 20px auto;
  padding: 0 20px;
}

.profile-card {
  background: #fff;
  border-radius: 8px;
  padding: 24px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}

.section-title {
  color: #303133;
  font-size: 18px;
  font-weight: 500;
  margin: 0 0 24px 0;
  padding-bottom: 12px;
  border-bottom: 1px solid #ebeef5;
}

.profile-form {
  max-width: 600px;
}

.profile-form :deep(.el-form-item) {
  position: relative;
}

.form-input :deep(.el-input__inner) {
  background-color: #f5f7fa;
  border-color: #e4e7ed;
}

.back-button {
  background: none;
  border: none;
  color: white;
  font-size: 16px;
  cursor: pointer;
  padding: 8px 16px;
  transition: opacity 0.3s;
}

.back-button:hover {
  opacity: 0.8;
}

.header-title {
  color: white;
  font-size: 20px;
  margin: 0;
  font-weight: 500;
}

.dialog-footer {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
}
</style>
