<template>
  <div>
    <h1>上传并查看图片</h1>
    <form @submit.prevent="uploadFile">
      <input type="file" ref="fileInput" />
      <button type="submit">上传</button>
    </form>
    <div v-if="imageUrl">
      <h2>上传的图片：</h2>
      <img :src="imageUrl" alt="Uploaded Image" style="max-width: 100%; height: auto;" />
    </div>
  </div>
</template>

<script>
import { uploadFile, getFileUrl } from '@/api/minio';

export default {
  data() {
    return {
      imageUrl: ''
    };
  },
  methods: {
    async uploadFile() {
      const fileInput = this.$refs.fileInput;
      const file = fileInput.files[0];

      if (!file) {
        alert('请选择一个文件');
        return;
      }

      // 创建表单数据
      const formData = new FormData();
      formData.append('file', file);

      try {
        // 上传文件
        const uploadResponse = await uploadFile(formData);

        const fileName = uploadResponse.data;
      console.log("fileName",fileName)
        // 获取文件URL
        const urlResponse = await getFileUrl(fileName);
        console.log("urlResponse",urlResponse)
        this.imageUrl = urlResponse.data;
      } catch (error) {
        console.error('上传失败:', error);
        alert('上传失败，请重试');
      }
    }
  }
};
</script>

<style scoped>
/* 添加你的样式 */
</style>
